<template>
    <div class="bg">
        <ToolbarVue title="SƠ ĐỒ CHỖ" />
        <!--=================================Chọn tầng xe==============================-->
        <div
            class="row align-center justify-center"
            style="height: 80px"
            v-if="getDanhSachTangXe.length > 0"
        >
            <div class="boundary">
                <div class="row">
                    <div
                        v-for="(item, index) in getDanhSachTangXe"
                        :key="index"
                    >
                        <div class="row align-center">
                            <DxButton
                                :id="`btn-tangxe${index}`"
                                :text="item.tenTang"
                                type="normal"
                                styling-mode="text"
                                :elementAttr="{
                                    class: `button-style ${
                                        item.active ? 'secondary--text' : ''
                                    }`,
                                }"
                                @click="chonTangXe(item)"
                            />
                            <div v-if="index % 2 == 0" class="line"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--=================================Sơ đồ chỗ==============================-->
        <div style="margin: 0 48px 16px" v-if="getManHinh == 'doc'">
            <SoDoChoVue
                @SoDoCho="soDoCho"
                direction="column"
                :item="{
                    width: '80px',
                    height: '80px',
                }"
                @canhBaoGhePhu="
                    ParamThongBao = {
                        state: 'Warning',
                        title: 'Thông báo!',
                        message_title: 'Xe chỉ còn chỗ phụ!',
                    };
                    dialogThongBao = true;
                "
            />
        </div>

        <div style="margin: 0 48px 16px" v-else>
            <SoDoChoManHinhNgang
                @SoDoCho="soDoCho"
                direction="column"
                :item="{
                    width: '80px',
                    height: '80px',
                }"
                @canhBaoGhePhu="
                    ParamThongBao = {
                        state: 'Warning',
                        title: 'Thông báo!',
                        message_title: 'Xe chỉ còn chỗ phụ!',
                    };
                    dialogThongBao = true;
                "
            />
        </div>
        <!--=================================Vi trí đang chọn==============================-->
        <div style="margin-left:32px;margin-right:32px" :class="getManHinh == 'doc' ? '' : 'custome-ngang'">
            <div class="white--text py-1">
                <div class="row justify-center align-center py-2">
                    <div style="font-size: 32px">Vị trí đang chọn</div>
                </div>
                <div
                    class="row justify-center wrap align-start"
                    style="max-height: 190px; overflow-y: auto"
                    :style="getManHinh == 'doc' ? '' : ' overflow-y: unset'"
                >
                    <DxButton
                        v-if="getDanhSachChoDaChon.length > 0"
                        text="Xóa tất cả"
                        type="danger"
                        styling-mode="outlined"
                        :rtlEnabled="true"
                        class="mx-1 mt-1 button-dachon-style"
                        icon="mdi mdi-close"
                        height="53px"
                        :width="getManHinh == 'doc' ? '' : '215px'"
                        style="order: 0"
                        @click="removeTatCaChoDaChon()"
                    />
                    <DxButton
                        v-for="(item, i) in getDanhSachChoDaChon"
                        :key="i"
                        :id="`chip-${i}`"
                        :text="`(${$MoneyFormat(item.giaTien)}đ) ${
                            item.tenCho
                        }`"
                        :rtlEnabled="true"
                        type="default"
                        class="mx-1 mt-1 button-dachon-style"
                        styling-mode="outlined"
                        height="53px"
                        icon="mdi mdi-close"
                        :width="getManHinh == 'doc' ? '' : '215px'"
                        @click="removeMotChoDaChon(Object.assign({}, item))"
                    />
                </div>
            </div>
        </div>
        <div class="" style="position: fixed; bottom: 50px; width: 100%">
            <div class="column justify-center align-center white--text">
                <div class="row justify-center">
                    <div style="font-size: 48px">Tổng tiền:&nbsp</div>
                    <div style="font-size: 48px" class="font-bold">
                        {{ `${$MoneyFormat(tongTienTheoChoDaChon)}đ` }}
                    </div>
                </div>
                <DxButton
                    id="btn-tieptuc"
                    text="tiếp tục"
                    type="default"
                    width="892px"
                    height="78px"
                    :disabled="getDanhSachChoDaChon.length == 0 ? true : false"
                    styling-mode="contained"
                    class="secondary--bg mt-4"
                    @click="xacNhanChonCho"
                />
            </div>
        </div>
        <!-- ====================================Popup Thông báo ========================================= -->
        <PopupsVue
            :Params="{
                ShowButtonLeft: false,
                ShowButtonRight: false,
                ShowButtonClose: false,
            }"
            Width="350"
            Height="auto"
            :Model="dialogThongBao"
        >
            <template #popups
                ><NotificationVue
                    :Params="ParamThongBao"
                    @click="dialogThongBao = false"
                    :showButtonLeft="false"
            /></template>
        </PopupsVue>
    </div>
</template>
<script>
import ToolbarVue from "../components/_Common/Toolbar";
import SoDoChoVue from "../components/SoDoCho";
import SoDoChoManHinhNgang from "../components/SoDoChoManHinhNgang"
import PopupsVue from "@sonphat/common-v1/components/Popups";
import { DxButton } from "devextreme-vue";
import { mapMutations, mapActions, mapGetters } from "vuex";
import NotificationVue from "@sonphat/common-v1/components/Notification";
export default {
    components: {
        ToolbarVue,
        SoDoChoVue,
        DxButton,
        PopupsVue,
        NotificationVue,
        SoDoChoManHinhNgang
    },
    props: {},
    data() {
        return {
            dialogThongBao: false,
            ParamThongBao: {
                state: "Error",
                title: "Thông báo!",
                message_title: "Đã xảy ra lỗi",
                message: "Vui lòng kiểm tra lại",
            },
        };
    },
    methods: {
        ...mapMutations("soDoCho", [
            "removeMotChoDaChon",
            "removeTatCaChoDaChon",
            "setTangXeDaChon",
            "setReloadMaTranCho",
            "setDanhSachTangXe",
        ]),
        ...mapActions("soDoCho", ["layDanhSachTangXe"]),
        ...mapActions("timKiemChuyenDi", ["kiemTraTrangThaiChuyenDi"]),
        chonTangXe(item) {
            let dsTangXe = this.getDanhSachTangXe.map((e) => {
                if (item.idTang == e.idTang) {
                    e.active = true;
                } else {
                    e.active = false;
                }
                return e;
            });
            this.setDanhSachTangXe(dsTangXe);
            this.setTangXeDaChon(item);
            this.setReloadMaTranCho();
        },
        soDoCho(item, data) {
            let soChoToiDa = this.getCauHinhChung.soChoToiDa;
            //Cảnh báo số chỗ tối đa được chọn
            if (data.length > (soChoToiDa || 6)) {
                this.ParamThongBao = {
                    state: "Warning",
                    title: "Thông báo!",
                    message_title: `Để đảm bảo an toàn phòng chống dịch, chỉ được chọn tối đa ${soChoToiDa} chỗ`,
                };
                this.dialogThongBao = true;
            }
        },
        async xacNhanChonCho() {
            //Kiểm tra để mua vé
            let check = await this.kiemTraTrangThaiChuyenDi({
                idXe: this.$route.query.chuyenDi_Id,
                soKhach: 0,
            });
            if (!check.data) {
                this.ParamThongBao = {
                    state: "Error",
                    title: "Thông báo!",
                    message_title: check.message,
                };
                this.dialogThongBao = true;
                return;
            }
            this.$router.push({
                path: "thong-tin-thanh-toan",
                query: this.$route.query,
            });
        },
    },
    computed: {
        ...mapGetters("soDoCho", [
            "getDanhSachTangXe",
            "getDanhSachChoDaChon",
            "tongTienTheoChoDaChon",
        ]),
        ...mapGetters("trangChu", ["getCauHinhChung"]),
        ...mapGetters("chiTietChuyenDi", ["getThongTinXeDangChon"]),
         getManHinh() {
            return this.$store.state.ManHinh.manHinh;
        },
    },
    created() {},
    async mounted() {
        await this.layDanhSachTangXe(this.$route.query.chuyenDi_Id);
        if (this.getDanhSachTangXe.length > 0) {
            this.chonTangXe(this.getDanhSachTangXe[0]);
        }
    },
};
</script>
<style lang='scss' scoped>
::v-deep.button-style {
    height: 58px;
}
::v-deep.button-style span {
    font-size: 28px;
    font-weight: unset;
}
::v-deep.button-style .dx-button-content {
    padding: 12px 32px;
}
.line {
    width: 1px;
    height: 40px;
    background-color: $border;
}
.boundary {
    border: 1px solid $primary;
}
.bg {
    background-image: url("../assets/images/vector-003.jpg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: auto;
    height: 100vh;
}
.chu-thich {
    border: 1px solid $border;
    border-radius: 30px;
    height: 200px;
    background-color: $background;
}
.hinhvuong {
    width: 43px;
    height: 43px;
    border-radius: $border_radius;
}
::v-deep#btn-tieptuc span {
    font-size: 28px;
}
::v-deep#btn-tieptuc {
    border-radius: 10px;
}
::v-deep.button-dachon-style .dx-button-text {
    text-transform: none !important;
    font-size: 22px;
}
::v-deep.button-dachon-style {
    background-color: white !important;
}
::v-deep.button-dachon-style .dx-icon {
    font-size: 32px;
    width: 32px;
    height: 32px;
    line-height: 32px;
}


.custome-ngang {
    position: fixed;
    top: 10%;
    width: 20%;
}
</style>